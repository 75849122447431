import React from "react";
import { Container, Figure } from "react-bootstrap";
// import ScrollableAnchor from "react-scrollable-anchor"
import Layout from "../components/layout";

// Star Backgrounds - Desktop
import pageStarsLeftTop from "../images/page-stars-left-top.png";
import pageStarsLeftBottom from "../images/page-stars-left-bottom.png";
import pageStarsRightTop from "../images/page-stars-right-top.png";
import pageStarsRightBottom from "../images/page-stars-right-bottom.png";

import barcode from "../images/barcode.png";

function Terms() {
  return (
    <>
      <Layout>
        <section className="main-content py-4 py-md-5 position-relative">
          <Container>
            <div className="faq text-white px-4 mt-5">
              <h4 className="text-center text-uppercase mb-4">{""}</h4>
              <h6 className="text-left text-uppercase">When can I enter?</h6>
              <p>
                The main promotion is open from August 23rd 2021 to November
                30th 2021, but there will also be a wrap up draw for entries
                received between December 1st 2021 and February 28th 2022.
              </p>

              <h6 className="text-left text-uppercase">What can I win?</h6>

              <p>
                There are 84,910 prizes to be won, consisting of: one of ten x
                Home Cinema Systems (9 for UK entries, 1 for ROI) or one of
                1000s of other prizes, including 2,900 Amazon Fire TV Sticks
                (2,813 for UK entries, 87 for ROI) provided in the form of a £50
                amazon.co.uk gift card, 2,000 M&M's merchandise bundles, each
                containing 2 t-shirts, 2 cushions, 2 pairs of socks and 4 packs
                of chocolate (1,940 for UK entries, 60 for ROI) and 80,000 packs
                of confectionery (77,600 for UK entries, 2,400 for ROI).
              </p>

              <h6 className="text-left text-uppercase">
                What do I win in the wrap up draw?
              </h6>
              <p>
                There will be two Home Cinema systems to be won, one for a UK
                entrant and one for an Irish entrant.
              </p>
              <h6>Where can I find my code?</h6>

              <img className="my-5 barcode" src={barcode} alt="barcode" />
              <h6 className="text-left text-uppercase">
                What packs are included in the promotion?
              </h6>
              <p>
                To enter, purchase a promotional pack of Galaxy® Block, Galaxy
                Minstrels®, M&M’s® Block, M&M’s® Chocolate, M&M’s® Crispy,
                M&M’s® Peanut, M&M’s® Salted Caramel, Maltesers®, Maltesers
                Buttons®, Milky Way®, Revels®, Skittles Chewies®, Skittles
                Fruits®, Skittles Giants®, Skittles Sours® and Starburst®
                Original.
              </p>

              {/* <h6 className="text-left text-uppercase">
                How do I know if I have won?
              </h6>
              <p>
                After entering your 4 digit code, full name and email address,
                you will be notified on screen immediately if you’re a winner.
              </p>

              <h6 className="text-left text-uppercase">
                Can I enter more than once?
              </h6>
              <p>A maximum of one entry will be accepted per person per day.</p>

              <h6 className="text-left text-uppercase">
                Can I win more than once?
              </h6>
              <p>
                If you are a very lucky person, you may win again. Just make
                sure you retain your promotional packaging as it may be required
                to verify your claim.
              </p>

              <h6 className="text-left text-uppercase">
                My pack code doesn’t work, what do I do?
              </h6>
              <p>
                Please check the code carefully to ensure you are entering the
                correct numbers. If you are still having problems, please
                contact our Consumer Care team{" "}
                <a href="/contact" target="_blank">
                  <u className="text-white">here</u>
                </a>
                .
              </p>

              <h6 className="text-left text-uppercase">
                How long do I have to wait to receive my prize?
              </h6>
              <p>
                If you’re a lucky winner, you should expect to receive your
                prize within 35 days from the day of your win.
              </p>

              <h6 className="text-left text-uppercase">
                Which products can I redeem the free pack of confectionery
                coupon prize against?
              </h6>
              <p>
                If you win a free pack of confectionery coupon, this can be
                redeemed at participating retailers nationwide against the
                following products: Galaxy Minstrels® standard pouch, Maltesers®
                standard pouch, M&M’s® Peanut standard pouch, Skittles Fruits®
                Family Size pouch and Galaxy® Block.
              </p>

              <h6 className="text-left text-uppercase">
                I don’t have access to a printer, how can I redeem my free pack
                of confectionery coupon?
              </h6>
              <p>
                You will need to wait until you have access to a desktop or
                laptop with connection to a working colour printer before
                clicking the PRINT button within your free pack of confectionery
                winner confirmation email. This is a one-time usage coupon only.{" "}
              </p>

              <h6 className="text-left text-uppercase">
                Do you need further assistance claiming your prize?
              </h6>
              <p>
                Please get in touch via the CONTACT US page on this site or call
                0800 952 0077 Mon- Fri 09:00 –18.00 (Bank Holiday-closed) and
                we'll be happy to assist you. *10 pence per minute from a BT
                landline, mobile and other providers may vary.
              </p> */}
            </div>
          </Container>

          <Figure className="mb-0 page-stars-left-top position-absolute">
            <Figure.Image src={pageStarsLeftTop} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 page-stars-left-bottom position-absolute">
            <Figure.Image src={pageStarsLeftBottom} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 page-stars-right-top position-absolute">
            <Figure.Image src={pageStarsRightTop} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 page-stars-right-bottom position-absolute">
            <Figure.Image src={pageStarsRightBottom} alt="Stars Right Bottom" />
          </Figure>
        </section>
        {/* </ScrollableAnchor> */}
      </Layout>
    </>
  );
}

export default Terms;
