import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { Heading } from "@pilot/react-components.heading";
import { Button as BitButton } from "@pilot/react-components.button";
import { InputElement } from "@pilot/react-components.input-element";

import { Container, Row, Col, Figure, Form, Button } from "react-bootstrap";

// Star Backgrounds - Mobile
import HomeStarsLeftTopM from "../images/stars-left-top-m.png";
import HomeStarsLeftBottomM from "../images/stars-left-bottom-m.png";
import HomeStarsRightTopM from "../images/stars-right-top-m.png";
import HomeStarsRightBottomM from "../images/stars-right-bottom-m.png";
// Star Backgrounds - Desktop
import HomeStarsLeftTop from "../images/home-stars-left-top.png";
import HomeStarsLeftCenter from "../images/home-stars-left-center.png";
import HomeStarsLeftBottom from "../images/home-stars-left-bottom.png";
import HomeStarsRightTop from "../images/home-stars-right-top.png";
import HomeStarsRightBottom from "../images/home-stars-right-bottom.png";

const AreYouWinner = (props) => {
  const [barcodeModalShow, setBarcodeModalShow] = useState(false);
  const [loading, setLoading] = useState(false);

  var formData;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // console.log(props.countryCode)
  const onSubmit = (data, event) => {
    event.preventDefault();
    // Email Validation
    let email = data.emailID;
    let emailCorrected;
    if (email.includes("@gmail.com") && email.includes("+")) {
      let emailWithPlus = email;
      let emailWithPlusSplit = emailWithPlus.split("+");
      emailCorrected = emailWithPlusSplit[0] + "@gmail.com";
    } else if (email.includes("@gmail.com") && email.includes(".")) {
      let emailWithDot = email;
      let emailWithDotSplit = emailWithDot.split(".");
      let emailWithDotJoin = emailWithDotSplit.join("");
      let emailWithDotJoinSplit = emailWithDotJoin.split("@");
      let emailWithDotJoinSplitJoin = emailWithDotJoinSplit[0] + "@gmail.com";
      emailCorrected = emailWithDotJoinSplitJoin;
    } else {
      emailCorrected = email;
    }
    // console.log(emailCorrected)
    data.emailID = emailCorrected.toLowerCase();

    var country = props.countryCode;
    if (country === "IEAnswered") country = "IE";
    data.countryCode = country;
    data.DataPurpose = false;
    data.GlobalPurpose = false;
    if (data.BrandPurpose === true) {
      data.MpsOptin = true;
    } else {
      data.MpsOptin = false;
    }
    formData = data;
    console.log(formData);
    props.setAreYouWinner(false);
    props.setWinnerDetailsSubmittedConfirmation(true);
  };

  return (
    <>
      <section className="main-content py-4 py-md-5 position-relative">
        <Container>
          <div className="text-white px-4">
            <div className="bit-dev bit-dev-heading">
              <Heading
                className="text-center text-uppercase heading heading-lg mb-0"
                id="home"
              >
                Are you a Winner?
              </Heading>
              <p className="bit-hover-card">
                <span
                  className="content"
                  style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                >
                  pilot.react-components
                </span>{" "}
                <span
                  className="content"
                  style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                >
                  heading@0.0.4
                </span>
              </p>
            </div>

            <Form
              className="home mt-4 mt-md-5"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <Row noGutters>
                <Col lg={{ span: 8, offset: 2 }}>
                  <Form.Group controlId="barcode" className="mb-4">
                    <div className="bit-dev bit-dev-heading">
                      <Heading
                        className="mb-4 text-uppercase d-inline-block"
                        element="h5"
                      >
                        Please retain your promotional pack
                      </Heading>
                      <p className="bit-hover-card">
                        <span
                          className="content"
                          style={{
                            borderRadius: "5px 0 0 5px",
                            padding: "0 3px",
                          }}
                        >
                          pilot.react-components
                        </span>{" "}
                        <span
                          className="content"
                          style={{
                            borderRadius: "0 5px 5px 0",
                            padding: "0 3px",
                          }}
                        >
                          heading@0.0.4
                        </span>
                      </p>
                    </div>
                    <div className="bit-dev bit-dev-input-element">
                      <InputElement
                        inputFeilds={[
                          {
                            type: "text",
                            id: "barcode",
                            className: "rounded-0",
                            inputClassName: "rounded-0",
                            maxLength: "4",
                            placeHolder:
                              "*Enter Last 4 Digits from Pack Barcode",
                            registerName: "voucherCode",
                            required: "Barcode is required",
                            inputMin: 4,
                            minMessage: "Please enter valid Barcode",
                          },
                        ]}
                        register={register}
                      />
                      <p className="bit-hover-card">
                        <span
                          className="content"
                          style={{
                            borderRadius: "5px 0 0 5px",
                            padding: "0 3px",
                          }}
                        >
                          pilot.react-components
                        </span>{" "}
                        <span
                          className="content"
                          style={{
                            borderRadius: "0 5px 5px 0",
                            padding: "0 3px",
                          }}
                        >
                          input-element@0.0.6
                        </span>
                      </p>
                    </div>
                    {errors.voucherCode && (
                      <span className="input-error">
                        {errors.voucherCode.message}
                      </span>
                    )}
                  </Form.Group>
                  {/* <div>
                    <h5
                      className="mb-4 text-uppercase d-inline-block"
                      onClick={() => setBarcodeModalShow(true)}
                      style={{ cursor: "pointer" }}
                    >
                      Where can I find my barcode?
                    </h5>
                  </div> */}
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="firstName" className="mb-4">
                        <div className="bit-dev bit-dev-input-element">
                          <InputElement
                            inputFeilds={[
                              {
                                type: "text",
                                className: "rounded-0",
                                inputClassName: "rounded-0",
                                placeHolder: "*First Name",
                                registerName: "firstName",
                                required: true,
                              },
                            ]}
                            register={register}
                          />
                          <p className="bit-hover-card">
                            <span
                              className="content"
                              style={{
                                borderRadius: "5px 0 0 5px",
                                padding: "0 3px",
                              }}
                            >
                              pilot.react-components
                            </span>{" "}
                            <span
                              className="content"
                              style={{
                                borderRadius: "0 5px 5px 0",
                                padding: "0 3px",
                              }}
                            >
                              input-element@0.0.6
                            </span>
                          </p>
                        </div>
                        {errors.firstName && (
                          <span className="input-error">
                            First name is required
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="lastName" className="mb-4">
                        <div className="bit-dev bit-dev-input-element">
                          <InputElement
                            inputFeilds={[
                              {
                                type: "text",
                                className: "rounded-0",
                                inputClassName: "rounded-0",
                                placeHolder: "*Last Name",
                                registerName: "lastName",
                                required: true,
                              },
                            ]}
                            register={register}
                          />
                          <p className="bit-hover-card">
                            <span
                              className="content"
                              style={{
                                borderRadius: "5px 0 0 5px",
                                padding: "0 3px",
                              }}
                            >
                              pilot.react-components
                            </span>{" "}
                            <span
                              className="content"
                              style={{
                                borderRadius: "0 5px 5px 0",
                                padding: "0 3px",
                              }}
                            >
                              input-element@0.0.6
                            </span>
                          </p>
                        </div>
                        {errors.lastName && (
                          <span className="input-error">
                            Last name is required
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group controlId="email" className="mb-4">
                    <div className="bit-dev bit-dev-input-element">
                      <InputElement
                        inputFeilds={[
                          {
                            type: "email",
                            className: "rounded-0",
                            inputClassName: "rounded-0",
                            placeHolder: "*Email Address",
                            registerName: "emailID",
                            required: "Email ID is Required",
                            regexExpression:
                              /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            requiredPatternMessage: "Invalid Email Address",
                          },
                        ]}
                        register={register}
                      />
                      <p className="bit-hover-card">
                        <span
                          className="content"
                          style={{
                            borderRadius: "5px 0 0 5px",
                            padding: "0 3px",
                          }}
                        >
                          pilot.react-components
                        </span>{" "}
                        <span
                          className="content"
                          style={{
                            borderRadius: "0 5px 5px 0",
                            padding: "0 3px",
                          }}
                        >
                          input-element@0.0.6
                        </span>
                      </p>
                    </div>
                    {errors.emailID && (
                      <span className="input-error">
                        {errors.emailID.message}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="phoneNumber" className="mb-4">
                    <div className="bit-dev bit-dev-input-element">
                      <InputElement
                        inputFeilds={[
                          {
                            type: "text",
                            className: "rounded-0",
                            inputClassName: "rounded-0",
                            placeHolder: "*Contact phone number",
                            registerName: "contactNumber",
                            required: true,
                          },
                        ]}
                        register={register}
                      />
                      <p className="bit-hover-card">
                        <span
                          className="content"
                          style={{
                            borderRadius: "5px 0 0 5px",
                            padding: "0 3px",
                          }}
                        >
                          pilot.react-components
                        </span>{" "}
                        <span
                          className="content"
                          style={{
                            borderRadius: "0 5px 5px 0",
                            padding: "0 3px",
                          }}
                        >
                          input-element@0.0.6
                        </span>
                      </p>
                    </div>
                    {errors.contactNumber && (
                      <span className="input-error">
                        Contact phone number is required
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group controlId="checkbox">
                    <div className="bit-dev bit-dev-input-element">
                      <InputElement
                        inputFeilds={[
                          {
                            type: "checkbox",
                            registerName: "BrandPurpose",
                            required: false,
                            id: "brandPurpose",
                            label: (
                              <p className="text-uppercase">
                                I would like to receive personalised marketing
                                offers from M&M’s and other Mars Wrigley’s
                                brands via email phone and other electronic
                                channels. You can with-draw this consent at any
                                time.
                              </p>
                            ),
                          },
                        ]}
                        register={register}
                      />
                      <p className="bit-hover-card">
                        <span
                          className="content"
                          style={{
                            borderRadius: "5px 0 0 5px",
                            padding: "0 3px",
                          }}
                        >
                          pilot.react-components
                        </span>{" "}
                        <span
                          className="content"
                          style={{
                            borderRadius: "0 5px 5px 0",
                            padding: "0 3px",
                          }}
                        >
                          input-element@0.0.6
                        </span>
                      </p>
                    </div>
                  </Form.Group>

                  <div className="text-center">
                    <div className="bit-dev bit-dev-button">
                      <BitButton
                        buttonId="btn-question"
                        primary={false}
                        className="rounded-pill text-uppercase btn btn-dark btn-lg"
                      >
                        Enter
                      </BitButton>
                      <p className="bit-hover-card">
                        <span
                          className="content"
                          style={{
                            borderRadius: "5px 0 0 5px",
                            padding: "0 3px",
                          }}
                        >
                          pilot.react-components
                        </span>{" "}
                        <span
                          className="content"
                          style={{
                            borderRadius: "0 5px 5px 0",
                            padding: "0 3px",
                          }}
                        >
                          button@0.0.3
                        </span>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </Container>

        {/* Background Star Images - Mobile */}
        <div className="d-md-none">
          <Figure className="mb-0 stars-left-top-m position-absolute">
            <Figure.Image src={HomeStarsLeftTopM} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 stars-left-center-m position-absolute">
            <Figure.Image src={HomeStarsLeftBottomM} alt="Stars Left Center" />
          </Figure>
          <Figure className="mb-0 stars-left-bottom-m position-absolute">
            <Figure.Image src={HomeStarsLeftBottomM} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 stars-right-top-m position-absolute">
            <Figure.Image src={HomeStarsRightTopM} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 stars-right-center-m position-absolute">
            <Figure.Image
              src={HomeStarsRightBottomM}
              alt="Stars Right Center"
            />
          </Figure>
          <Figure className="mb-0 stars-right-bottom-m position-absolute">
            <Figure.Image
              src={HomeStarsRightBottomM}
              alt="Stars Right Bottom"
            />
          </Figure>
        </div>

        {/* Background Star Images - Desktop */}
        <div className="d-none d-md-block">
          <Figure className="mb-0 home-stars-left-top position-absolute">
            <Figure.Image src={HomeStarsLeftTop} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 home-stars-left-center position-absolute">
            <Figure.Image src={HomeStarsLeftCenter} alt="Stars Left Center" />
          </Figure>
          <Figure className="mb-0 home-stars-left-bottom position-absolute">
            <Figure.Image src={HomeStarsLeftBottom} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 home-stars-right-top position-absolute">
            <Figure.Image src={HomeStarsRightTop} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 home-stars-right-bottom position-absolute">
            <Figure.Image src={HomeStarsRightBottom} alt="Stars Right Bottom" />
          </Figure>
        </div>
      </section>
    </>
  );
};

export default AreYouWinner;
