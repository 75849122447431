/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { useEffect, useState } from "react";

import { Footer as BitFooter } from "@pilot/react-components.footer"

import "../css/app.css";
import "../css/responsive.css";

import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, ageGateShow, setAgeGateShow }) => {
  const linksArray = [
    {
      name: "privacy",
      href: "https://www.mars.com/privacy",
      className: "text-uppercase text-dark text-decoration-none",
    },
    {
      name: "cookies",
      href: "https://www.mars.com/cookies-english",
      className: "text-uppercase text-dark text-decoration-none",
    },
    {
      name: "legal",
      href: "https://www.mars.com/legal",
      className: "text-uppercase text-dark text-decoration-none",
    },
    {
      name: "CA Supply Chain Transparency act",
      href: "https://www.mars.com/about/policies-and-practices/ca-supply-chain-transparency-act",
      className: "text-uppercase text-dark text-decoration-none",
    },
    {
      name: "accessibility",
      href: "https://www.mars.com/accessibility",
      className: "text-uppercase text-dark text-decoration-none",
    },
    {
      name: "modern slavery act",
      href: "https://www.mars.com/about/policies-and-practices/modern-slavery-act",
      className: "text-uppercase text-dark text-decoration-none",
    },
    {
      name: "Adchoices",
      href: "https://www.mars.com/mars-incorporated-adchoices-united-states",
      className: "text-uppercase text-dark text-decoration-none",
    },
  ];
  return (
    <>
      <Header ageGateShow={ageGateShow} setAgeGateShow={setAgeGateShow} />

      <main>{children}</main>

      <Footer />

      <div className="bit-dev bit-dev-footer">
        <BitFooter links={linksArray} />
        <p className="bit-hover-card">
          <span
            className="content"
            style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
          >
            pilot.react-components
          </span>{" "}
          <span
            className="content"
            style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
          >
            footer@0.0.4
          </span>
        </p>
      </div>
    </>
  );
};

export default Layout;
