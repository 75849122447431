import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from "axios";

import Home from "./pages/Home";
import Terms from "./pages/terms";
import Prizes from "./pages/prizes";
import Faq from "./pages/faq";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const App = () => {
  const [token, setToken] = useState(false);
  const [login, setLogin] = useState(false);
  let isVerify = new Cookies();
  useEffect(() => {
    if (
      isVerify.get("isVerified") === undefined ||
      isVerify.get("isVerified") === null ||
      !isVerify.get("isVerified")
    ) {
      setToken(false);
    } else {
      axios
        .get("https://stage.api.promos.mars.com/stage-login/validate", {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            Authorization: `Bearer ${isVerify.get("isVerified")}`,
          },
        })
        .then(function (response) {
          if (response.data.isVerified) {
            setToken(true);
          } else {
            setToken(false);
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
          setToken(false);
        });
    }

    return () => {
      setToken(false);
    };
  }, [login]);

  if (!isVerify.get("isVerified")) {
    return (
      <div className="d-flex align-items-center justify-content-center position-absolute top-0 bottom-0 start-0 end-0 px-3 bg-white">
        <form className="mx-auto" style={{ width: "500px" }}>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              Username
            </label>
            <input
              type="text"
              className="form-control"
              id="username"
              aria-describedby="username"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Password
            </label>
            <input type="password" className="form-control" id="password" />
          </div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              let username = document.getElementById("username").value;
              let password = document.getElementById("password").value;
              axios
                .post("https://stage.api.promos.mars.com/stage-login", {
                  username: username,
                  password: password,
                })
                .then(function (response) {
                  if (response.data.isVerified) {
                    isVerify.set("isVerified", response.data.token, {
                      path: "/",
                      maxAge: 86400,
                    });
                    // localStorage.setItem("isVerified", response.data.token)
                    // document.location.reload(true);
                    setLogin(true);
                  } else {
                    // document.location.reload(true);
                    setLogin(false);
                  }
                })
                .catch(function (error) {
                  console.log(error);
                  setLogin(false);
                });
            }}
          >
            Submit
          </button>
        </form>
      </div>
    );
  } else {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/terms" component={Terms} />
          <Route path="/prizes" component={Prizes} />
          <Route path="/faq" component={Faq} />
        </Switch>
      </Router>
    );
  }
};

export default App;
