import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import { Heading } from "@pilot/react-components.heading";
import { Button as BitButton } from "@pilot/react-components.button";

import { Container, Row, Button, Figure } from "react-bootstrap";

// Star Backgrounds - Mobile
import StarsLeftTopM from "../images/stars-left-top-m.png";
import StarsLeftBottomM from "../images/stars-left-bottom-m.png";
import StarsRightTopM from "../images/stars-right-top-m.png";
import StarsRightBottomM from "../images/stars-right-bottom-m.png";

// Star Backgrounds - Desktop
import StarsLeft from "../images/stars-left.png";
import StarsRight from "../images/stars-right.png";

const CountrySelection = (props) => {
  let isCountrySelected = new Cookies();
  let ageCookie = new Cookies();
  useEffect(() => {
    var country = isCountrySelected.get("isCountrySelected");
    if (isCountrySelected.get("isCountrySelected") === "GB") {
      props.setCountrySelection(false);
      props.setAreYouWinner({ state: true, countryCode: country });
    } else if (isCountrySelected.get("isCountrySelected") === "IE") {
      props.setCountrySelection(false);
      props.setRoiQuestion({ state: true, countryCode: country });
    } else if (isCountrySelected.get("isCountrySelected") === "IEAnswered") {
      props.setCountrySelection(false);
      props.setAreYouWinner({ state: true, countryCode: country });
    } else {
      isCountrySelected.set("isCountrySelected", "no", { path: "/" });
      props.setCountrySelection(true);
    }
  }, []);
  const ukhandler = () => {
    if (ageCookie.get("isOldEnough") === "yes") {
      isCountrySelected.set("isCountrySelected", "GB", {
        path: "/",
        maxAge: 2592000,
        // secure: true,
        // httpOnly: true,
        // sameSite: true,
      });
    }

    props.setCountrySelection(false);
    props.setAreYouWinner({ state: true, countryCode: "GB" });
  };
  const roihandler = () => {
    if (ageCookie.get("isOldEnough") === "yes") {
      isCountrySelected.set("isCountrySelected", "IE", {
        path: "/",
        maxAge: 2592000,
        // secure: true,
        // httpOnly: true,
        // sameSite: true,
      });
    }
    props.setCountrySelection(false);
    props.setRoiQuestion({ state: true, countryCode: "IE" });
  };
  return (
    <>
      <section className="country-selection main-content py-4 py-md-5 position-relative">
        <Container>
          <div className="text-white px-4">
            <div className="bit-dev bit-dev-heading">
              <Heading className="text-center text-uppercase heading mb-0">
                Are you entering from{" "}
                <span className="d-md-block">UK or Republic of Ireland?</span>
              </Heading>
              <p className="bit-hover-card">
                <span
                  className="content"
                  style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                >
                  pilot.react-components
                </span>{" "}
                <span
                  className="content"
                  style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                >
                  heading@0.0.4
                </span>
              </p>
            </div>
            <Row className="justify-content-center">
              <div className="bit-dev bit-dev-button d-contents">
                <div className="position-relative mt-4" onClick={roihandler}>
                  <BitButton
                    buttonId="btn-country"
                    primary={false}
                    className="rounded-pill text-uppercase mx-2 btn btn-danger btn-lg"
                  >
                    Republic of Ireland
                  </BitButton>
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      button@0.0.3
                    </span>
                  </p>
                </div>
              </div>
              <div className="bit-dev bit-dev-button d-contents">
                <div className="position-relative mt-4" onClick={ukhandler}>
                  <BitButton
                    buttonId="btn-country"
                    primary={false}
                    className="rounded-pill text-uppercase mx-2  btn btn-dark btn-lg"
                  >
                    UK
                  </BitButton>
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      button@0.0.3
                    </span>
                  </p>
                </div>
              </div>
            </Row>
          </div>
        </Container>

        {/* Background Star Images - Mobile */}
        <div className="d-md-none">
          <Figure className="mb-0 stars-left-top-m position-absolute">
            <Figure.Image src={StarsLeftTopM} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 stars-left-bottom-m position-absolute">
            <Figure.Image src={StarsLeftBottomM} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 stars-right-top-m position-absolute">
            <Figure.Image src={StarsRightTopM} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 stars-right-bottom-m position-absolute">
            <Figure.Image src={StarsRightBottomM} alt="Stars Right Bottom" />
          </Figure>
        </div>

        {/* Background Star Images - Desktop */}
        <div className="d-none d-md-block">
          <Figure className="mb-0 stars-left position-absolute">
            <Figure.Image src={StarsLeft} alt="Stars Left" />
          </Figure>
          <Figure className="mb-0 stars-right position-absolute">
            <Figure.Image src={StarsRight} alt="Stars Right" />
          </Figure>
        </div>
      </section>
    </>
  );
};

export default CountrySelection;
