import React from "react";

import { Container } from "react-bootstrap";
import { Heading } from "@pilot/react-components.heading";

const WinnerDetailsSubmittedConfirmation = () => {
  return (
    <>
      <section className="main-content py-4 position-relative">
        <Container>
          <div className="text-white px-2">
            <div className="bit-dev bit-dev-heading">
              <Heading
                className="text-center text-uppercase heading heading-lg mb-0"
                id="home"
              >
                Winner!
              </Heading>
              <p className="bit-hover-card">
                <span
                  className="content"
                  style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                >
                  pilot.react-components
                </span>{" "}
                <span
                  className="content"
                  style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                >
                  heading@0.0.4
                </span>
              </p>
            </div>
            <div className="bit-dev bit-dev-heading">
              <Heading className="text-center text-uppercase mt-4" element="h2">
                All Done!
              </Heading>
              <p className="bit-hover-card">
                <span
                  className="content"
                  style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                >
                  pilot.react-components
                </span>{" "}
                <span
                  className="content"
                  style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                >
                  heading@0.0.4
                </span>
              </p>
            </div>
            <div className="bit-dev bit-dev-heading">
              <Heading
                className="text-center text-uppercase mb-3 px-xl-5"
                element="h3"
              >
                Thanks for your details, we've sent you an email confirmation
                and our team will get going on processing the dispatch of your
                prize.
              </Heading>

              <p className="bit-hover-card">
                <span
                  className="content"
                  style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                >
                  pilot.react-components
                </span>{" "}
                <span
                  className="content"
                  style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                >
                  heading@0.0.4
                </span>
              </p>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default WinnerDetailsSubmittedConfirmation;
