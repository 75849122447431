import React, { useState, useEffect } from "react";

import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";

import { Container, Figure, Form, Button } from "react-bootstrap";
import { Heading } from "@pilot/react-components.heading";
import { Button as BitButton } from "@pilot/react-components.button";
import { InputElement } from "@pilot/react-components.input-element";

import mmsRed from "../images/mms-red.png";
import mmsPurple from "../images/mms-purple.png";
import mmsYellow from "../images/mms-yellow.png";

// Star Backgrounds - Mobile
import RoiStarsLeftTopM from "../images/stars-left-top-m.png";
import RoiStarsLeftBottomM from "../images/stars-left-bottom-m.png";
import RoiStarsRightTopM from "../images/stars-right-top-m.png";
import RoiStarsRightBottomM from "../images/stars-right-bottom-m.png";

// Star Backgrounds - Desktop
import RoiStarsLeftTop from "../images/roi-stars-left-top.png";
import RoiStarsLeftCenter from "../images/roi-stars-left-center.png";
import RoiStarsLeftBottom from "../images/roi-stars-left-bottom.png";
import RoiStarsRightTop from "../images/roi-stars-right-top.png";
import RoiStarsRightBottom from "../images/roi-stars-right-bottom.png";

const RoiQuestion = (props) => {
  const [wrongAnswerMessage, setWrongAnswerMessage] = useState(false);
  const [selectQuestionError, setSelectQuestionError] = useState(false);
  const [imgState, setImgState] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const red = new Image();
    red.onload = () => {
      setImgState(true);
    };
    const purple = new Image();
    purple.onload = () => {
      setImgState(true);
    };
    const yellow = new Image();
    yellow.onload = () => {
      setImgState(true);
    };
    red.src = mmsRed;
    purple.src = mmsPurple;
    yellow.src = mmsYellow;
  }, []);

  let ageCookie = new Cookies();
  let isCountrySelected = new Cookies();

  const onSubmit = (data, event) => {
    event.preventDefault();
    console.log(data);
    if (data.rioQuestion === "Purple") {
      setSelectQuestionError(false);
      setWrongAnswerMessage(false);
      props.setRoiQuestion({
        state: false,
        countryCode: props.countryCode,
      });
      props.setAreYouWinner({
        state: true,
        countryCode: props.countryCode,
      });
      if (ageCookie.get("isOldEnough") === "yes") {
        isCountrySelected.set("isCountrySelected", "IEAnswered", {
          path: "/",
          maxAge: 2592000,
          // secure: true,
          // httpOnly: true,
          // sameSite: true,
        });
      }
    } else {
      setSelectQuestionError(false);
      setWrongAnswerMessage(true);
    }
  };
  const onErrors = (errors, event) => {
    event.preventDefault();
    setSelectQuestionError(true);
    setWrongAnswerMessage(false);
  };
  return (
    <>
      {imgState && (
        <section className="rio-question main-content py-4 py-md-5 position-relative">
          <Container>
            <div className="text-white px-2">
              <div className="bit-dev bit-dev-heading">
                <Heading className="text-center text-uppercase heading py-md-4 mb-0">
                  Which of the below colours{" "}
                  <span className="d-md-block">
                    is not an M&amp;M's<sup>&reg;</sup> Character?
                  </span>
                </Heading>
                <p className="bit-hover-card">
                  <span
                    className="content"
                    style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                  >
                    pilot.react-components
                  </span>{" "}
                  <span
                    className="content"
                    style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                  >
                    heading@0.0.4
                  </span>
                </p>
              </div>
              <Form
                className="roi-form"
                onSubmit={handleSubmit(onSubmit, onErrors)}
              >
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <div className="bit-dev bit-dev-input-element">
                    <InputElement
                      inputFeilds={[
                        {
                          className: "custom-control custom-radio",
                          inputClassName: "custom-control-input",
                          labelClassName: "custom-control-label",
                          type: "radio",
                          registerName: "rioQuestion",
                          required: true,
                          id: "mmsRed",
                          name: "roi-question",
                          value: "Red",
                          label: (
                            <div className="text-center">
                              <Figure className="px-2 px-md-4 mb-0 w-75 mx-auto">
                                <Figure.Image src={mmsRed} alt="MMS Red" />
                              </Figure>
                              <h3 className="text-center text-uppercase my-3">
                                Red
                              </h3>
                            </div>
                          ),
                        },
                      ]}
                      register={register}
                    />
                    <p className="bit-hover-card">
                      <span
                        className="content"
                        style={{
                          borderRadius: "5px 0 0 5px",
                          padding: "0 3px",
                        }}
                      >
                        pilot.react-components
                      </span>{" "}
                      <span
                        className="content"
                        style={{
                          borderRadius: "0 5px 5px 0",
                          padding: "0 3px",
                        }}
                      >
                        input-element@0.0.6
                      </span>
                    </p>
                  </div>
                  <div className="bit-dev bit-dev-input-element">
                    <InputElement
                      inputFeilds={[
                        {
                          className: "custom-control custom-radio",
                          inputClassName: "custom-control-input",
                          labelClassName: "custom-control-label",
                          type: "radio",
                          registerName: "rioQuestion",
                          required: true,
                          id: "mmsPurple",
                          name: "roi-question",
                          value: "Purple",
                          label: (
                            <div className="text-center">
                              <Figure className="px-2 px-md-4 mb-0 w-75 mx-auto">
                                <Figure.Image
                                  src={mmsPurple}
                                  alt="MMS Purple"
                                />
                              </Figure>
                              <h3 className="text-center text-uppercase my-3">
                                Purple
                              </h3>
                            </div>
                          ),
                        },
                      ]}
                      register={register}
                    />
                    <p className="bit-hover-card">
                      <span
                        className="content"
                        style={{
                          borderRadius: "5px 0 0 5px",
                          padding: "0 3px",
                        }}
                      >
                        pilot.react-components
                      </span>{" "}
                      <span
                        className="content"
                        style={{
                          borderRadius: "0 5px 5px 0",
                          padding: "0 3px",
                        }}
                      >
                        input-element@0.0.6
                      </span>
                    </p>
                  </div>
                  <div className="bit-dev bit-dev-input-element">
                    <InputElement
                      inputFeilds={[
                        {
                          className: "custom-control custom-radio",
                          inputClassName: "custom-control-input",
                          labelClassName: "custom-control-label",
                          type: "radio",
                          registerName: "rioQuestion",
                          required: true,
                          id: "mmsYellow",
                          name: "roi-question",
                          value: "Yellow",
                          label: (
                            <div className="text-center">
                              <Figure className="px-2 px-md-4 mb-0 w-75 mx-auto">
                                <Figure.Image
                                  src={mmsYellow}
                                  alt="MMS Yellow"
                                />
                              </Figure>
                              <h3 className="text-center text-uppercase my-3">
                                Yellow
                              </h3>
                            </div>
                          ),
                        },
                      ]}
                      register={register}
                    />
                    <p className="bit-hover-card">
                      <span
                        className="content"
                        style={{
                          borderRadius: "5px 0 0 5px",
                          padding: "0 3px",
                        }}
                      >
                        pilot.react-components
                      </span>{" "}
                      <span
                        className="content"
                        style={{
                          borderRadius: "0 5px 5px 0",
                          padding: "0 3px",
                        }}
                      >
                        input-element@0.0.6
                      </span>
                    </p>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <div className="bit-dev bit-dev-button">
                    <BitButton
                      buttonId="btn-question"
                      primary={false}
                      className="rounded-pill text-uppercase btn btn-dark btn-lg"
                    >
                      Submit
                    </BitButton>
                    <p className="bit-hover-card">
                      <span
                        className="content"
                        style={{
                          borderRadius: "5px 0 0 5px",
                          padding: "0 3px",
                        }}
                      >
                        pilot.react-components
                      </span>{" "}
                      <span
                        className="content"
                        style={{
                          borderRadius: "0 5px 5px 0",
                          padding: "0 3px",
                        }}
                      >
                        button@0.0.3
                      </span>
                    </p>
                  </div>
                </div>
                {wrongAnswerMessage && (
                  <div className="bit-dev bit-dev-heading">
                    <Heading
                      className="error text-center text-uppercase mt-4 mb-0"
                      element="h4"
                    >
                      Oops, Wrong Answer. Please try again.
                    </Heading>
                    <p className="bit-hover-card">
                      <span
                        className="content"
                        style={{
                          borderRadius: "5px 0 0 5px",
                          padding: "0 3px",
                        }}
                      >
                        pilot.react-components
                      </span>{" "}
                      <span
                        className="content"
                        style={{
                          borderRadius: "0 5px 5px 0",
                          padding: "0 3px",
                        }}
                      >
                        heading@0.0.4
                      </span>
                    </p>
                  </div>
                )}

                {selectQuestionError && (
                  <div className="bit-dev bit-dev-heading">
                    <Heading
                      className="error text-center text-uppercase mt-4 mb-0"
                      element="h4"
                      style={{ color: "#ffd204" }}
                    >
                      Please select atleast one
                    </Heading>
                    <p className="bit-hover-card">
                      <span
                        className="content"
                        style={{
                          borderRadius: "5px 0 0 5px",
                          padding: "0 3px",
                        }}
                      >
                        pilot.react-components
                      </span>{" "}
                      <span
                        className="content"
                        style={{
                          borderRadius: "0 5px 5px 0",
                          padding: "0 3px",
                        }}
                      >
                        heading@0.0.4
                      </span>
                    </p>
                  </div>
                )}
              </Form>
            </div>
          </Container>

          {/* Background Star Images - Mobile */}
          <div className="d-md-none">
            <Figure className="mb-0 stars-left-top-m position-absolute">
              <Figure.Image src={RoiStarsLeftTopM} alt="Stars Left Top" />
            </Figure>
            <Figure className="mb-0 stars-left-center-m position-absolute">
              <Figure.Image src={RoiStarsLeftBottomM} alt="Stars Left Center" />
            </Figure>
            <Figure className="mb-0 stars-left-bottom-m position-absolute">
              <Figure.Image src={RoiStarsLeftBottomM} alt="Stars Left Bottom" />
            </Figure>
            <Figure className="mb-0 stars-right-top-m position-absolute">
              <Figure.Image src={RoiStarsRightTopM} alt="Stars Right Top" />
            </Figure>
            <Figure className="mb-0 stars-right-center-m position-absolute">
              <Figure.Image
                src={RoiStarsRightBottomM}
                alt="Stars Right Center"
              />
            </Figure>
            <Figure className="mb-0 stars-right-bottom-m position-absolute">
              <Figure.Image
                src={RoiStarsRightBottomM}
                alt="Stars Right Bottom"
              />
            </Figure>
          </div>

          {/* Background Star Images - Desktop */}
          <div className="d-none d-md-block">
            <Figure className="mb-0 roi-stars-left-top position-absolute">
              <Figure.Image src={RoiStarsLeftTop} alt="Stars Left Top" />
            </Figure>
            <Figure className="mb-0 roi-stars-left-center position-absolute">
              <Figure.Image src={RoiStarsLeftCenter} alt="Stars Left Center" />
            </Figure>
            <Figure className="mb-0 roi-stars-left-bottom position-absolute">
              <Figure.Image src={RoiStarsLeftBottom} alt="Stars Left Bottom" />
            </Figure>
            <Figure className="mb-0 roi-stars-right-top position-absolute">
              <Figure.Image src={RoiStarsRightTop} alt="Stars Right Top" />
            </Figure>
            <Figure className="mb-0 roi-stars-right-bottom position-absolute">
              <Figure.Image
                src={RoiStarsRightBottom}
                alt="Stars Right Bottom"
              />
            </Figure>
          </div>
        </section>
      )}
    </>
  );
};

export default RoiQuestion;
