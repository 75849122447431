import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";

import Layout from "../components/layout";
import Agegate from "../components/agegate";
import CountrySelection from "../components/country-selection";
import RoiQuestion from "../components/roi-question";
import AreYouWinner from "../components/are-you-winner";
import WinnerDetailsSubmittedConfirmation from "../components/winner-details-submitted-confirmation";


const Home = () => {
  const [ageGateShow, setAgeGateShow] = useState(true);
  const [countrySelection, setCountrySelection] = useState(false);
  const [roiQuestion, setRoiQuestion] = useState({
    state: false,
    countryCode: "",
  });
  const [areYouWinner, setAreYouWinner] = useState({
    state: false,
    countryCode: "",
  });
  const [
    winnerDetailsSubmittedConfirmation,
    setWinnerDetailsSubmittedConfirmation,
  ] = useState(false);
  let ageCookie = new Cookies();
  useEffect(() => {
    if (ageCookie.get("isOldEnough") === "yes") {
      setAgeGateShow(false);
      setCountrySelection(true);
    } else {
      ageCookie.set("isOldEnough", "no");
      setAgeGateShow(true);
    }
  }, []);
  return (
    <Layout
      ageGateShow={ageGateShow}
      setAgeGateShow={setAgeGateShow}
      countrySelection={countrySelection}
      roiQuestion={roiQuestion.state}
      areYouWinner={areYouWinner}
      setAreYouWinner={setAreYouWinner}
    >
      {ageGateShow && (
        <Agegate
          setAgeGateShow={setAgeGateShow}
          setCountrySelection={setCountrySelection}
        />
      )}
      {countrySelection && (
        <CountrySelection
          setCountrySelection={setCountrySelection}
          setRoiQuestion={setRoiQuestion}
          setAreYouWinner={setAreYouWinner}
        />
      )}
      {roiQuestion.state && (
        <RoiQuestion
          setRoiQuestion={setRoiQuestion}
          setAreYouWinner={setAreYouWinner}
          countryCode={roiQuestion.countryCode}
        />
      )}
      {areYouWinner.state && (
        <AreYouWinner
          setAreYouWinner={setAreYouWinner}
          setWinnerDetailsSubmittedConfirmation={
            setWinnerDetailsSubmittedConfirmation
          }
        />
      )}
      {winnerDetailsSubmittedConfirmation && (
        <WinnerDetailsSubmittedConfirmation />
      )}
    </Layout>
  );
};

export default Home;
