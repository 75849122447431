import React, { useState } from "react";
import { Container, Row, Col, Figure, Modal, Button } from "react-bootstrap";
import { Header as NavBar } from "@pilot/react-components.header";
import { ContentWithImage } from "@pilot/react-components.content-with-image";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// import Home from "../App";
// import Faq from "../faq";
// import Prizes from "../prizes";
// import Terms from "../terms";

import TextPlusLogos from "../images/text-plus-logos.png";

const Header = ({ ageGateShow, setAgeGateShow }) => {
  let mainNav;
  mainNav = (
    <div class="bit-dev bit-dev-header">
      <NavBar
        menuIcon={false}
        reactLink={false}
        linksAlignment="right"
        textColor={"#531e0d"}
        menuLinks={[
          { name: "Home", href: "/" },
          { name: "PRIZES", href: "/prizes" },
          { name: "FAQS", href: "/faq" },
          { name: "T&CS", href: "/terms" },
        ]}
      />
      <p className="bit-hover-card">
        <span
          className="content"
          style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
        >
          pilot.react-components
        </span>{" "}
        <span
          className="content"
          style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
        >
          header@0.0.5
        </span>
      </p>
    </div>
  );
  // }
  return (
    <>
      <div className="main-header">
        {mainNav}
        <Container>
          <div class="bit-dev bit-dev-image-with-content">
            <ContentWithImage
              className="bg-hero"
              imageBlockClass="col-md-6"
              figureClass="mb-0 p-4 px-md-0 py-md-5 my-md-5"
              imageSrc={TextPlusLogos}
              imageAlt="TV Show"
            />
            <p className="bit-hover-card">
              <span
                className="content"
                style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
              >
                pilot.react-components
              </span>{" "}
              <span
                className="content"
                style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
              >
                content-with-image@0.0.4
              </span>
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
