import React from "react";
import { Container, Figure } from "react-bootstrap";
// import ScrollableAnchor from "react-scrollable-anchor"

import Layout from "../components/layout";

// Star Backgrounds - Desktop
import pageStarsLeftTop from "../images/page-stars-left-top.png";
import pageStarsLeftBottom from "../images/page-stars-left-bottom.png";
import pageStarsRightTop from "../images/page-stars-right-top.png";
import pageStarsRightBottom from "../images/page-stars-right-bottom.png";

function Terms() {
  return (
    <>
      <Layout>
        <section className="main-content py-4 py-md-5 position-relative">
          <Container>
            <div className="text-white px-3">
              <p>
                1. Entry is open to UK and ROI residents aged 18 and over only,
                excluding employees of the Promoter, their family members or
                anyone else associated with the administration of this
                promotion.
              </p>

              <p>
                2. To enter, purchase a promotional pack of Galaxy® Block,
                Galaxy Minstrels®, M&M’s® Block, M&M’s® Chocolate, M&M’s®
                Crispy, M&M’s® Peanut, M&M’s® Salted Caramel, Maltesers®,
                Maltesers Buttons®, Milky Way®, Revels®, Skittles Chewies®,
                Skittles Fruits®, Skittles Giants®, Skittles Sours® and
                Starburst® Original and enter the last four digits of the
                barcode found on the outside of the pack, together with your
                contact details including email address at
                www.mms-streaming.co.uk. Promotional packs subject to
                availability. ROI residents will be required to answer a
                skill-based question before being able to enter their four-digit
                barcode.
              </p>

              <p>
                3. Promotional barcode digits to be registered between 00.01 on
                23/08/21 and 23.59 on 30/11/21 (the "Promotional Period").
              </p>

              <p>
                4. Internet access required. Promotional barcode digits cannot
                be submitted by any other means.
              </p>

              <p>
                5. No purchase necessary for Northern Ireland residents only.
                Send a stamped addressed envelope with your full name, NI postal
                address and email address to: M&M's Streaming NPN Mars Wrigley
                Confectionery UK LTD, Box 3856, Dublin 4, between 23/08/21 and
                30/11/21. Only one free entry claim per envelope. Only one no
                purchase necessary entry per Northern Ireland resident per day
                throughout the Promotional Period. On validation of a Northern
                Ireland postcode you will receive barcode digits by email for
                registering in accordance with the process described above. All
                valid NPN entries will have an equal and random chance of
                winning via an independently supervised process.
              </p>

              <p>
                6. A maximum of one entry will be accepted per person per day.
                No entries from agents, third parties or organised groups will
                be accepted. No bulk entries.
              </p>

              <p>
                7. Packaging and receipt should be retained following
                registration of promotional barcode digits, as it may be
                required to verify a winning claim.
              </p>

              <p>
                8. 84,910 winning moments have been securely selected and
                randomly allocated across the entire Promotional Period. The
                first entrant registering valid barcode digits during one of
                these winning moments will win a prize. If no valid barcode
                digits are registered during a winning moment, the prize
                allocated to that winning moment will be awarded to the first
                person registering a valid entry after that winning moment.
                Winners will be notified on-screen immediately if they have won
                a prize or not. Winners of the Home Cinema Systems, Amazon Fire
                TV Sticks and M&M’s bundles will be required to confirm postal
                address details to which their Prize should be delivered.
              </p>

              <p>
                9. There are 84,910 prizes to be won in total. The Prize pool of
                84,910 prizes will be allocated randomly as follows: one of ten
                x Home Cinema Systems (9 for UK entries, 1 for ROI) or one of
                1000s of other prizes, including 2,900 Amazon Fire TV Sticks
                (2,813 for UK entries, 87 for ROI) provided in the form of a £50
                amazon.co.uk gift card, 2,000 M&M's merchandise bundles, each
                containing 2 t-shirts, 2 cushions, 2 pairs of socks and 4 packs
                of chocolate (1,940 for UK entries, 60 for ROI) and 80,000
                coupons for a free pack of the following confectionery products:
                Galaxy Minstrels® standard pouch, Maltesers® standard pouch,
                M&M’s® Peanut standard pouch, Skittles Fruits® Family Size pouch
                and Galaxy® Block (77,600 for UK entries, 2,400 for ROI). A
                maximum of one Prize can be won per person per day. Prizes of
                Home Cinema Systems, £50 amazon.co.uk gift cards and M&M’s
                merchandise bundles will be received by winners within 35 days
                of notification and confirmation of their postal address.
                Winners of a free pack coupon will receive their prize by email
                immediately, sent to the email address provided when registering
                to participate.
              </p>

              <p>
                10. Entrants between 01/12/21 and 28/02/22 will be entered into
                a further wrap-up draw to be held at the end of the Promotional
                Period for the chance to win one of two Home Cinema Systems (1
                for UK, 1 for ROI). Winners in the wrap-up draw will be notified
                by no later than 07/03/22.
              </p>

              <p>
                11. Winners of the Home Cinema Systems in the wrap-up draw will
                be required to confirm postal address details to which their
                Prize should be delivered. Wrap-up draw winners will receive
                their prizes within 28 days of confirming their address details.
              </p>
              <p>
                12. The £50 amazon.co.uk gift cards are subject to the issuer’s
                terms and conditions of use to be found at{" "}
                <a
                  target="_blank"
                  style={{ color: "unset" }}
                  href="https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=GNG9PXYZUMQT72QK"
                >
                  <u>
                    https://www.amazon.co.uk/gp/help/customer/display.html?nodeId=GNG9PXYZUMQT72QK
                  </u>
                </a>{" "}
                Winners will need an existing amazon.co.uk account or need to
                register for one to be able to redeem their gift card.
                Amazon.co.uk gift cards can be redeemed up to 10 years from date
                of issue.
              </p>
              <p>
                13. The Promoter is not responsible or liable for:
                <span class="d-block">
                  a) Any entries that fail to be registered or are delayed due
                  to faulty or failed electronic data transmissions;
                </span>
                <span class="d-block">
                  b) Communications line failure, regardless of cause, with
                  regard to any equipment, systems, networks, lines satellites,
                  servers, computers or providers utilised in any aspect of this
                  promotion causing delays or disruption;
                </span>
                <span class="d-block">
                  c) Entries which are corrupted, damaged, misdirected or
                  incomplete or cannot be delivered for any technical, delivery
                  or other reason. Proof of submitting a promotional code cannot
                  be accepted as proof of receipt by the Promoter;
                </span>
                <span class="d-block">
                  d) A winner of a Prize failing to receive their Prize as a
                  result of having provided incorrect postal or email address
                  details during the registration and notification processes;
                </span>
                <span class="d-block">
                  e) The failure of any third party to fulfil its obligations in
                  connection with this promotion, although the Promoter will
                  endeavour to minimise the effect of any such failure on the
                  entrants and the prize winners.
                </span>
              </p>
              <p>
                14. All Prizes are as stated, are non-transferable and there are
                no alternatives available. The Promoter reserves the right to
                award alternative prizes of equal or greater value, in the event
                that any of the original Prizes become unavailable for any
                reason.
              </p>
              <p>
                15. Except where winner(s) have notified the Promoter that they
                do not want their details to be shared, details of the winners
                of the Home Cinema Systems (all abbreviated names and counties
                only) will be available for a 4-week period from 01/12/2021 by
                emailing contact@ukmw.mars.com for the main promotion and for a
                4-week period from 01/03/2022 for the wrap-up draw.
              </p>
              <p>
                16. By participating in the promotion and accepting these Terms
                and Conditions when registering promotional barcode digits.
              </p>
              <p>
                17. If the Promoter has reasonable grounds to believe that a
                winner of a Prize has breached these terms and conditions or has
                otherwise manipulated the code registration or prize draw
                process, that winner will be disqualified and any claim they
                have for a Prize will be invalidated.
              </p>
              <p>
                18. In the event of circumstances outside the reasonable control
                of the Promoter impacting its ability to operate the promotion
                as planned, or otherwise where fraud, abuse, and/or an error
                (human or computer) affects or could affect the proper operation
                of this promotion or the awarding of Prizes, the Promoter
                reserves the right to suspend the promotion or amend these terms
                and conditions, at any stage, with no liability to any entrants
                or third parties, but will always endeavour to minimise the
                effect to participants in order to avoid undue disappointment.
              </p>
              <p>
                19. These terms and conditions are governed by English Law and
                shall be subject to the exclusive jurisdiction of the English
                Courts. The courts of the Republic of Ireland have jurisdiction
                over any claims or disputes brought by residents of the Republic
                of Ireland.
              </p>
              <p>
                20. The Promoter will use personal details supplied by entrants
                in accordance with Condition 15 and for the administration of
                the promotion and for no other purpose unless an entrant has
                consented to other uses. Click to see the Promoter’s privacy
                policy here:
                <a
                  target="_blank"
                  style={{ color: "unset" }}
                  href="https://www.mars.com/privacy"
                >
                  <u>http://www.mars.com/uk/en/policies/privacy/pp-english</u>
                </a>
                . Except where consent has been provided, personal details of
                entrants will be deleted within 30 days of the end of the
                Promotional Period or within 30 days of the end of the wrap-up
                period, as appropriate. Any Prize winner can request for their
                details to be deleted before the expiry of the relevant period
                by emailing the address in Condition 15 although the Promoter
                cannot be held responsible if such request means that it is not
                possible for delivery of that winner's prize to be completed.
              </p>
            </div>
          </Container>

          <Figure className="mb-0 page-stars-left-top position-absolute">
            <Figure.Image src={pageStarsLeftTop} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 page-stars-left-bottom position-absolute">
            <Figure.Image src={pageStarsLeftBottom} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 page-stars-right-top position-absolute">
            <Figure.Image src={pageStarsRightTop} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 page-stars-right-bottom position-absolute">
            <Figure.Image src={pageStarsRightBottom} alt="Stars Right Bottom" />
          </Figure>
        </section>
        {/* </ScrollableAnchor> */}
      </Layout>
    </>
  );
}

export default Terms;
