import React, { useState } from "react";
import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import { InputElement } from "@pilot/react-components.input-element";
import { Button } from "@pilot/react-components.button";

const AgegateComponenet = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [ageFlag, setAgeFlag] = useState(false);
  function generateArray(start, end) {
    let arr = [];
    for (start; start <= end; start++) {
      arr.push({ option: start, value: start });
    }
    return arr;
  }
  const months = [
    { option: "Jan", value: "Jan" },
    { option: "Feb", value: "Feb" },
    { option: "Mar", value: "Mar" },
    { option: "Apr", value: "Apr" },
    { option: "May", value: "May" },
    { option: "Jun", value: "Jun" },
    { option: "Jul", value: "Jul" },
    { option: "Aug", value: "Aug" },
    { option: "Sep", value: "Sep" },
    { option: "Oct", value: "Oct" },
    { option: "Nov", value: "Nov" },
    { option: "Dec", value: "Dec" },
  ];
  //1-31
  const days = generateArray(1, 31);
  //1900-today
  const years = generateArray(1900, new Date().getFullYear()).reverse();

  //calculate age
  function calculateAge(birthday) {
    //milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
    let birthArry = birthday.split(" ");
    if (
      birthArry[1] === "Date" ||
      birthArry[1] === "null" ||
      birthArry[0] === "Month" ||
      birthArry[0] === "null" ||
      birthArry[2] === "Year" ||
      birthArry[2] === "null"
    ) {
      return 0;
    }
    let today = new Date(),
      //birthay has 'Dec 25 1998'
      dob = new Date(birthday),
      //difference in milliseconds
      diff = today.getTime() - dob.getTime(),
      //convert milliseconds into years
      years = Math.floor(diff / 31556736000),
      //1 day has 86400000 milliseconds
      days_diff = Math.floor((diff % 31556736000) / 86400000),
      //1 month has 30.4167 days
      months = Math.floor(days_diff / 30.4167),
      days = Math.floor(days_diff % 30.4167);

    return years;
  }

  let ageCookie = new Cookies();

  const onSubmit = (data, event) => {
    event.preventDefault();
    var day, month, year;
    day = data.day;
    month = data.month;
    year = data.year;
    let age = calculateAge(`${data.month} ${data.day} ${data.year}`);
    if (age >= 18) {
      ageCookie.set("isOldEnough", "yes", {
        path: "/",
        maxAge: 2592000,
        // secure: true,
        // httpOnly: true,
        // sameSite: true,
      });

      props.setAgeGateShow(false);
      props.setCountrySelection(true);
    } else if (
      day == "Date" ||
      day == null ||
      month == "Month" ||
      month == null ||
      year == "Year" ||
      year == null
    ) {
      props.setAgeGateShow(true);
      props.setCountrySelection(false);
      setAgeFlag(false);
      props.setIsOldError(true);
      props.setAgeError(false);
    } else if (age < 18) {
      props.setAgeGateShow(true);
      props.setCountrySelection(false);
      setAgeFlag(false);
      props.setIsOldError(false);
      props.setAgeError(true);
    }
  };
  const onErrors = (errors, event) => {
    event.preventDefault();
    props.setIsOldError(true);
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit, onErrors)}>
        <div className="d-flex justify-content-center">
          <div className="bit-dev bit-dev-input-element">
            <InputElement
              inputFeilds={[
                {
                  className: "mx-2 form-group",
                  type: "select",
                  registerName: "day",
                  required: true,
                  placeHolder: "Date",
                  selectOptionsData: days,
                },
              ]}
              register={register}
            />
            <p className="bit-hover-card">
              <span
                className="content"
                style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
              >
                pilot.react-components
              </span>{" "}
              <span
                className="content"
                style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
              >
                input-element@0.0.6
              </span>
            </p>
          </div>
          <div className="bit-dev bit-dev-input-element">
            <InputElement
              inputFeilds={[
                {
                  className: "mx-2 form-group",
                  type: "select",
                  registerName: "month",
                  required: true,
                  placeHolder: "Month",
                  selectOptionsData: months,
                },
              ]}
              register={register}
            />
            <p className="bit-hover-card">
              <span
                className="content"
                style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
              >
                pilot.react-components
              </span>{" "}
              <span
                className="content"
                style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
              >
                input-element@0.0.6
              </span>
            </p>
          </div>
          <div className="bit-dev bit-dev-input-element">
            <InputElement
              inputFeilds={[
                {
                  className: "mx-2 form-group",
                  type: "select",
                  registerName: "year",
                  required: true,
                  placeHolder: "Year",
                  selectOptionsData: years,
                },
              ]}
              register={register}
            />
            <p className="bit-hover-card">
              <span
                className="content"
                style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
              >
                pilot.react-components
              </span>{" "}
              <span
                className="content"
                style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
              >
                input-element@0.0.6
              </span>
            </p>
          </div>
        </div>
        <div className="bit-dev bit-dev-button my-4">
          <Button
            buttonId="btn-agegate"
            primary={false}
            className="rounded-pill text-uppercase mx-2 btn btn-dark btn-lg"
          >
            Enter
          </Button>
          <p className="bit-hover-card">
            <span
              className="content"
              style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
            >
              pilot.react-components
            </span>{" "}
            <span
              className="content"
              style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
            >
              button@0.0.3
            </span>
          </p>
        </div>
      </Form>
      {ageFlag && (
        <div>
          <span className="error">Below 16Years old are not allowed.</span>
        </div>
      )}
    </>
  );
};

export default AgegateComponenet;
