import React from "react";
import { Container, Figure } from "react-bootstrap";
// import ScrollableAnchor, { configureAnchors } from "react-scrollable-anchor"
import { Heading } from "@pilot/react-components.heading";
import { Image } from "@pilot/react-components.image";

import Layout from "../components/layout";

import tvimage from "../images/TV.png";
import GiftCard from "../images/gift-card.png";
import AmazonBundle from "../images/amazon-bundle.png";
import Firetv from "../images/firetvstick.png";

// Star Backgrounds - Desktop
import pageStarsLeftTop from "../images/page-stars-left-top.png";
import pageStarsLeftBottom from "../images/page-stars-left-bottom.png";
import pageStarsRightTop from "../images/page-stars-right-top.png";
import pageStarsRightBottom from "../images/page-stars-right-bottom.png";
import ogImg from "../images/ogimg.png";
import freepack from "../images/freepack.png";
// configureAnchors({ offset: -30 })

function Prizes() {
  return (
    <>
      <Layout>
        <section className="main-content prizes py-4 py-md-5 position-relative">
          <Container>
            <div className="text-white px-4 text-center">
              <div className="bit-dev bit-dev-heading">
                <Heading
                  element="h4"
                  className="text-center text-uppercase mb-4"
                >
                  We have some Amazing prizes up for grabs
                </Heading>
                <p className="bit-hover-card">
                  <span
                    className="content"
                    style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                  >
                    pilot.react-components
                  </span>{" "}
                  <span
                    className="content"
                    style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                  >
                    heading@0.0.4
                  </span>
                </p>
              </div>
              <div>
                <div className="bit-dev bit-dev-image">
                  <Image
                    className={"mb-3"}
                    src={tvimage}
                    altText="Tv and Soundbar"
                  />
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      image@0.0.3
                    </span>
                  </p>
                </div>
                <div className="bit-dev bit-dev-heading">
                  <Heading
                    element="h5"
                    className="text-center text-uppercase mb-0"
                  >
                    10 X Home cinema Systems
                  </Heading>
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      heading@0.0.4
                    </span>
                  </p>
                </div>
                <div className="bit-dev bit-dev-heading">
                  <Heading
                    element="h5"
                    className="text-center text-uppercase mb-5"
                  >
                    A Tv and SoundBar
                  </Heading>
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      heading@0.0.4
                    </span>
                  </p>
                </div>
              </div>
              <div>
                <div className="bit-dev bit-dev-image">
                  <Image
                    className={"mt-5 mb-3"}
                    src={Firetv}
                    altText="Amazon Gift card"
                  />
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      image@0.0.3
                    </span>
                  </p>
                </div>
                <div className="bit-dev bit-dev-heading">
                  <Heading
                    element="h5"
                    className="text-center text-uppercase mb-0"
                  >
                    2,900x Amazon Fire TV Sticks provided
                  </Heading>
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      heading@0.0.4
                    </span>
                  </p>
                </div>
                <div className="bit-dev bit-dev-heading">
                  <Heading
                    element="h5"
                    className="text-center text-uppercase mb-5"
                  >
                    in the form of a £50 amazon.co.uk gift card
                  </Heading>
                  <p className="bit-hover-card">
                    <span
                      className="content"
                      style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
                    >
                      pilot.react-components
                    </span>{" "}
                    <span
                      className="content"
                      style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
                    >
                      heading@0.0.4
                    </span>
                  </p>
                </div>
              </div>
              {/* <div>
                <img
                  className="mb-5 img-fluid"
                  src={AmazonBundle}
                  alt="Amazon Bundle"
                />
                <h5 className="text-center text-uppercase mb-0">
                  2,000 x M&M's Bundles
                </h5>
                <h5 className="text-center text-uppercase mb-5">
                  Each bundle contains 2 x T-shirts, 2 X cushions,
                  <span className="d-md-block">
                    2 X pairs of socks and 4 x packs of chocolate
                  </span>
                </h5>
              </div>
              <div>
                <img
                  className="mb-5 img-fluid"
                  src={freepack}
                  alt="Free pack"
                />
                <h4 className="text-center text-uppercase">
                  80,000x coupons for a free pack of confectionery.
                </h4>
              </div>
              <img className="d-none" src={ogImg} alt="og image" /> */}
            </div>
          </Container>

          <Figure className="mb-0 page-stars-left-top position-absolute">
            <Figure.Image src={pageStarsLeftTop} alt="Stars Left Top" />
          </Figure>
          <Figure className="mb-0 page-stars-left-bottom position-absolute">
            <Figure.Image src={pageStarsLeftBottom} alt="Stars Left Bottom" />
          </Figure>
          <Figure className="mb-0 page-stars-right-top position-absolute">
            <Figure.Image src={pageStarsRightTop} alt="Stars Right Top" />
          </Figure>
          <Figure className="mb-0 page-stars-right-bottom position-absolute">
            <Figure.Image src={pageStarsRightBottom} alt="Stars Right Bottom" />
          </Figure>
        </section>
        {/* </ScrollableAnchor> */}
      </Layout>
    </>
  );
}

export default Prizes;
