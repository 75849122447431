// import { Link } from "gatsby"
import React from "react";
import { Container, Figure } from "react-bootstrap";
import { Image } from "@pilot/react-components.image";

import Packs from "../images/packs.png";
import PacksStarsLeft from "../images/packs-stars-left.png";
import PacksStarsRight from "../images/packs-stars-right.png";

const Footer = () => (
  <>
    <footer className="main-footer py-5">
      <Container fluid>
        <div className="py-md-5 position-relative">
          <div className="bit-dev bit-dev-image">
            <Image
              useFigureTag={true}
              figureClassName="mb-0 packs position-relative"
              src={Packs}
              alt="TV Show"
              imgClassName="w-100"
            />
            <p className="bit-hover-card">
              <span
                className="content"
                style={{ borderRadius: "5px 0 0 5px", padding: "0 3px" }}
              >
                pilot.react-components
              </span>{" "}
              <span
                className="content"
                style={{ borderRadius: "0 5px 5px 0", padding: "0 3px" }}
              >
                image@0.0.3
              </span>
            </p>
          </div>

          <Figure className="mb-0 packs-stars-left position-absolute">
            <Figure.Image src={PacksStarsLeft} alt="Stars Left" />
          </Figure>

          <Figure className="mb-0 packs-stars-right position-absolute">
            <Figure.Image src={PacksStarsRight} alt="Stars Right" />
          </Figure>
        </div>
      </Container>
    </footer>
  </>
);

export default Footer;
